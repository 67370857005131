import { useEffect, useMemo, useRef, useState } from "react";
import {
  View,
  Image,
  Platform,
  Animated,
  Easing,
  BackHandler,
  useWindowDimensions
} from "react-native";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@rneui/themed";
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context";
import { isSupported } from "twilio-video";
import Feather from "react-native-vector-icons/Feather";

import { useJoinMemberRoomQuery } from "common/services/VideoService";
import TwilioVideoCallHandleType from "common/components/twilio/TwilioVideoCallHandleType";
import TwilioVideoCallComponent from "common/components/twilio/TwilioVideoCallComponent";
import {
  PermissionsHelper_askForCamera,
  PermissionsHelper_askForMicrophone,
  PermissionsHelper_openSettings
} from "common/helpers/PermissionsHelper";
import { RootState, useAppDispatch } from "common/redux";

import useScreenType, { ScreenTypeEnum } from "../hooks/useScreenType";
import { isFalsy } from "common/helpers/helpers";
import PushNotificationTypeEnum from "common/enums/PushNotificationTypeEnum";

import IconButton from "./ui/IconButton";
import ScreenContainer from "./ui/ScreenContainer";
import Button from "./ui/Button";
import Spacing from "./ui/Spacing";
import { copilotIQLogo } from "../assets/common";
import Text from "./ui/Text";
import useTextStyles from "./ui/styles/useTextStyles";
import AppInstallButtons from "./AppInstallButtons";
import useKeyboard from "../hooks/useKeyboard";
import TouchableOpacity from "./ui/TouchableOpacity";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { useHideTabBar } from "../navigation/NavigationUtils";
import { useFocusEffect } from "@react-navigation/native";
import Helmet from "../components/Helmet";
import { usePushNotificationForeground } from "common/helpers/firebase/PushNotificationsHelper";
import { SentryHelper_captureException } from "common/helpers/SentryHelper";
import { useBluetoothHeadsetDetection } from "common/nativemodules/BluetoothHeadsetDetect";
import LocalizedStrings from "../helpers/LocalizedStrings";
import {
  AnalyticsHelper_identify,
  AnalyticsHelper_logEvent
} from "common/helpers/firebase/AnalyticsHelper";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";

const BANNER_DURATION_SECONDS = 6;

interface ErrorType {
  type: string;
  name: string;
  message: string;
}

const VideoCallComponent = ({ route, navigation }) => {
  const insets = useSafeAreaInsets();
  const keyboardVisible = useKeyboard();
  const { theme } = useTheme();
  const dispatch = useAppDispatch();
  const bluetoothDevice = useBluetoothHeadsetDetection();

  const textStyles = useTextStyles();

  const { type } = useScreenType();

  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  const [errorType, setErrorType] = useState<ErrorType>();
  const [permissionsAccepted, setPermissionsAccepted] = useState<boolean>();

  const [showParticipantList, setShowParticipantList] =
    useState<boolean>(false);
  const [isConnected, setConnected] = useState<boolean>(false);
  const [isAudioEnabled, setAudioEnabled] = useState<boolean>(true);
  const [isVideoEnabled, setVideoEnabled] = useState<boolean>(true);
  const [isSoundSetupEnabled, setSoundSetupEnabled] = useState<boolean>(true);
  const [joined, setJoined] = useState<boolean>(Platform.OS !== "web");

  const [memberId, setMemberId] = useState<string>();

  const { code } = route.params;

  const { data: member } = useGetAuthenticatedMember();

  const { data: roomData, error } = useJoinMemberRoomQuery(
    { code },
    { skip: code === undefined }
  );

  const [bannerVisible, setBannerVisible] = useState<boolean>(true);

  const bannerProgressBar = useRef(new Animated.Value(0)).current;
  const bannerHidden = useRef(new Animated.Value(0)).current;

  const twilioRef = useRef<TwilioVideoCallHandleType>();

  const dimensions = useWindowDimensions();

  const bannerHeight = useMemo(() => {
    return 60 * dimensions.fontScale + insets.top;
  }, [insets.top, dimensions]);

  const styles = useStyles({ insets, keyboardVisible, bannerHeight });

  const onBannerClosed = () => {
    Animated.timing(bannerHidden, {
      toValue: -bannerHeight,
      duration: 200,
      easing: Easing.linear,
      useNativeDriver: Platform.OS !== "web"
    }).start(() => {
      setBannerVisible(false);
    });
  };

  function handleBackButtonClick() {
    onDisconnectPressed();
    return true;
  }

  useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", handleBackButtonClick);
    return () => {
      BackHandler.removeEventListener(
        "hardwareBackPress",
        handleBackButtonClick
      );
    };
  }, []);

  useEffect(() => {
    setBannerVisible(true);
    Animated.timing(bannerProgressBar, {
      toValue: 1,
      duration: BANNER_DURATION_SECONDS * 1000,
      easing: Easing.linear,
      useNativeDriver: false
    }).start(() => {
      onBannerClosed();
    });
  }, []);

  useEffect(() => {
    if (error !== undefined) {
      endCall();
    }
  }, [error]);

  const handleNotificationReceived = (remoteMessage) => {
    if (
      remoteMessage.data.type === PushNotificationTypeEnum.VIDEO_ROOM_CLOSED
    ) {
      Alert_show({
        dispatch,
        title: LocalizedStrings.screens.videoCall.callEndedModalTitle,
        content: LocalizedStrings.screens.videoCall.callEndedModalContent,
        buttons: [
          {
            text: LocalizedStrings.common.close,
            onPress: () => {
              endCall();
            }
          }
        ]
      });
    }
  };

  usePushNotificationForeground(handleNotificationReceived);

  const endCall = () => {
    twilioRef?.current?.disconnect();

    if (navigation.canGoBack()) navigation.goBack();
    else {
      const route = isLoggedIn ? "Dashboard" : "Landing";
      navigation.reset({
        index: 0,
        routes: [{ name: route }]
      });
    }
  };

  const onDisconnectPressed = () => {
    Alert_show({
      dispatch,
      id: "endCall",
      type: "warning",
      title: LocalizedStrings.screens.videoCall.callEndModalTitle,
      content: LocalizedStrings.screens.videoCall.callEndModalContent,
      buttons: [
        {
          text: LocalizedStrings.common.yes,
          style: "destructive",
          onPress: () => {
            endCall();

            Alert_close({ dispatch, id: "endCall" });
          }
        },
        {
          text: LocalizedStrings.common.no,
          onPress: () => {
            Alert_close({ dispatch, id: "endCall" });
          }
        }
      ]
    });
  };
  const onFlipPressed = () => {
    twilioRef.current.flipCamera();
  };

  const onMutePressed = () => {
    setAudioEnabled((prevState) => {
      twilioRef.current.setAudioEnabled(!prevState);
      return !prevState;
    });
  };

  const onVideoPressed = () => {
    setVideoEnabled((prevState) => {
      twilioRef.current.setVideoEnabled(!prevState);
      return !prevState;
    });
  };

  const toggleSoundSetup = () => {
    setSoundSetupEnabled((prevState) => {
      twilioRef.current.toggleSoundSetup(!prevState);
      return !prevState;
    });
  };

  const toggleParticipantList = () => {
    setShowParticipantList((prevState) => {
      twilioRef.current.setShowParticipants(!prevState);
      return !prevState;
    });
  };

  const onConnected = () => {
    setConnected(true);
  };

  const checkPermissions = () => {
    return new Promise<"ok">((resolve, reject) => {
      PermissionsHelper_askForMicrophone()
        .then(() => PermissionsHelper_askForCamera())
        .then(() => {
          setErrorType(undefined);
          setPermissionsAccepted(true);
          resolve("ok");
        })
        .catch((error) => {
          SentryHelper_captureException(
            [error.type, error.name, error.message].join(","),
            { level: "info" }
          );
          AnalyticsHelper_logEvent("VideoCall_ErrorPermissions", {
            error: JSON.stringify(error),
            member_id: memberId,
            user_id: memberId
          });
          setErrorType(error);
          setPermissionsAccepted(false);
          reject(error);
        });
    });
  };

  useEffect(() => {
    if (roomData != undefined) {
      AnalyticsHelper_identify(roomData.member_id);
      setMemberId(roomData.member_id);
    }
    if (
      isFalsy(twilioRef?.current) ||
      roomData === undefined ||
      isFalsy(permissionsAccepted) ||
      joined === false
    )
      return;

    twilioRef.current.connect(roomData.access_token, roomData.member_id);
  }, [twilioRef, roomData, permissionsAccepted, joined]);

  useFocusEffect(() => {
    if (!joined) return;
    checkPermissions().catch(() => {});
  });

  useHideTabBar(navigation, type !== ScreenTypeEnum.PHONE);

  const supported = Platform.OS !== "web" || isSupported;

  useEffect(() => {
    if (!supported && memberId) {
      SentryHelper_captureException(
        "Browser not supported for Twilio Video Calls",
        {
          level: "info"
        }
      );
      AnalyticsHelper_logEvent("VideoCall_ErrorBrowserNotSupported", {
        member_id: memberId,
        user_id: memberId
      });
    }
  }, [supported, memberId]);

  useEffect(() => {
    if (supported && !joined && memberId)
      AnalyticsHelper_logEvent("VideoCall_Landing", {
        member_id: memberId,
        user_id: memberId
      });
  }, [supported, joined, memberId]);

  // Please note this when testing on localhost.
  // https://github.com/twilio/twilio-video.js/issues/1128#issuecomment-672222533
  // Check webpack.config.js devServer parameters
  if (!supported) {
    return (
      <ScreenContainer innerStyle={styles.notSupportedContainer}>
        <Image style={styles.copilotLogo} source={copilotIQLogo} />
        <Spacing vertical={4} />
        <Text
          h4
          style={[textStyles.colorDarkGreyBlue, textStyles.textAlignCenter]}
        >
          {LocalizedStrings.screens.videoCall.notSupported}
        </Text>

        <Spacing vertical={5} />

        <Text
          h4
          style={[textStyles.colorDarkGreyBlue, textStyles.textAlignCenter]}
        >
          {LocalizedStrings.screens.videoCall.downloadApp}
        </Text>

        <Spacing vertical={5} />

        <AppInstallButtons />

        <Text
          h4
          style={[textStyles.colorDarkGreyBlue, textStyles.textAlignCenter]}
        >
          {LocalizedStrings.screens.videoCall.orUseSupportedBrowser}
        </Text>
      </ScreenContainer>
    );
  }

  if (!joined) {
    return (
      <ScreenContainer innerStyle={styles.errorContainer}>
        <Image
          style={styles.copilotLogo}
          resizeMode={"center"}
          source={copilotIQLogo}
        />

        <Spacing vertical={10}></Spacing>
        <Button
          title="Join Call with Provider"
          onPress={() => {
            checkPermissions()
              .then(() => setJoined(true))
              .catch(() => {});
          }}
        />
      </ScreenContainer>
    );
  }

  if (errorType !== undefined) {
    return (
      <ScreenContainer innerStyle={styles.errorContainer}>
        <Text
          h4
          style={[textStyles.colorDarkGreyBlue, textStyles.textAlignCenter]}
        >
          {errorType.type === "UserPermissionDenied"
            ? LocalizedStrings.screens.videoCall.errorAcceptPermissions
            : errorType?.message}
        </Text>

        <Spacing vertical={5} />

        {Platform.OS === "web" ? (
          <Button
            title={LocalizedStrings.common.retry}
            onPress={checkPermissions}
          />
        ) : (
          <Button
            title={LocalizedStrings.screens.videoCall.openSettings}
            onPress={() => PermissionsHelper_openSettings(dispatch)}
          />
        )}
      </ScreenContainer>
    );
  }

  return (
    <View style={styles.container}>
      <Helmet />
      <View style={styles.callContainer}>
        <TwilioVideoCallComponent
          ref={twilioRef}
          member_id={member?.patient?.patient_id ?? memberId}
          bottomInsets={insets.bottom + 10}
          topInsets={insets.top}
          isVideoEnabled={isVideoEnabled}
          onConnected={onConnected}
        />
      </View>

      <View style={styles.buttonContainer}>
        <IconButton
          onPress={toggleParticipantList}
          icon={"users"}
          color={showParticipantList ? theme.colors.grey1 : theme.colors.white}
          buttonSize={48}
          iconColor={theme.colors.tealBlue}
          disabled={!isConnected}
        />

        {Platform.OS !== "web" && bluetoothDevice === null && (
          <IconButton
            onPress={toggleSoundSetup}
            icon={isSoundSetupEnabled ? "volume-2" : "volume-1"}
            color={theme.colors.white}
            buttonSize={48}
            iconColor={theme.colors.tealBlue}
            disabled={!isConnected}
          />
        )}
        <IconButton
          onPress={onVideoPressed}
          icon={isVideoEnabled ? "video" : "video-off"}
          color={theme.colors.white}
          buttonSize={48}
          iconColor={theme.colors.tealBlue}
          disabled={!isConnected}
        />
        <IconButton
          onPress={onMutePressed}
          icon={isAudioEnabled ? "mic" : "mic-off"}
          color={theme.colors.white}
          buttonSize={48}
          iconColor={theme.colors.tealBlue}
          disabled={!isConnected}
        />
        {Platform.OS !== "web" && (
          <IconButton
            onPress={onFlipPressed}
            icon="repeat"
            color={theme.colors.white}
            buttonSize={48}
            iconColor={theme.colors.tealBlue}
            disabled={!isConnected}
          />
        )}
        <IconButton
          onPress={onDisconnectPressed}
          icon="phone-missed"
          color={theme.colors.white}
          buttonSize={48}
          iconColor={theme.colors.error}
        />
      </View>

      {bannerVisible && (
        <Animated.View
          style={[
            styles.bannerContainer,
            { transform: [{ translateY: bannerHidden }] }
          ]}
        >
          <View style={styles.bannerInnerContainer}>
            <View style={{ width: 24 }} />
            <Text
              style={[
                textStyles.colorDarkGreyBlue,
                textStyles.textAlignCenter,
                { flex: 1 }
              ]}
            >
              {LocalizedStrings.screens.videoCall.callBeingRecorded}
            </Text>
            <TouchableOpacity onPress={onBannerClosed}>
              <Feather name={"x"} color={theme.colors.darkGreyBlue} size={24} />
            </TouchableOpacity>
          </View>

          <View style={styles.bannerProgressBarContainer}>
            <Animated.View
              style={{
                backgroundColor: theme.colors.white,
                flex: bannerProgressBar
              }}
            />
            <Animated.View
              style={{
                flex: Animated.subtract(
                  new Animated.Value(1),
                  bannerProgressBar
                ),
                backgroundColor: theme.colors.grey3
              }}
            />
          </View>
        </Animated.View>
      )}
    </View>
  );
};

interface StyleProp {
  insets: EdgeInsets;
  keyboardVisible: boolean;
  bannerHeight: number;
}

const useStyles = makeStyles(
  (theme, { insets, keyboardVisible, bannerHeight }: StyleProp) => {
    return {
      notSupportedContainer: {
        flex: 1,
        justifyContent: "center",
        marginHorizontal: 20
      },
      errorContainer: {
        flex: 1,
        justifyContent: "center",
        alignSelf: "center",
        maxWidth: 500,
        marginHorizontal: 20
      },
      noIdentityContainer: {
        flex: 1,
        alignItems: "center",
        justifyContent: keyboardVisible ? "flex-start" : "center",
        marginTop: keyboardVisible ? insets.top : 0,
        marginHorizontal: 20
      },
      noIdentityInnerContainer: {
        width: 350
      },
      container: {
        flex: 1,
        backgroundColor: theme.colors.black
      },
      bannerContainer: {
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        height: bannerHeight,
        backgroundColor: theme.colors.warning
      },
      bannerInnerContainer: {
        flexDirection: "row",
        flex: 1,
        alignItems: "center",
        marginTop: 10 + insets.top,
        marginHorizontal: 10,
        marginBottom: 10
      },
      bannerProgressBarContainer: {
        flexDirection: "row",
        height: 5
      },
      copilotLogo: {
        width: 154,
        height: 50,
        alignSelf: "center"
      },
      callContainer: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      },
      buttonContainer: {
        position: "absolute",
        bottom: insets.bottom + 10,
        flexDirection: "row",
        alignSelf: "center",
        width: "100%",
        maxWidth: 400,
        justifyContent: "space-evenly"
      }
    };
  }
);

export default VideoCallComponent;
